import React from "react"

// import local utils
import Layout from "@utils/layout"
import Seo from "@utils/seo"

// import local components
import SectionHeader from "@molecules/Sections/SectionHeader"
import SectionReviews from "@molecules/Sections/SectionReviews"
import SectionSellcar from "@molecules/Sections/SectionSellcar"
import SectionVendors from "@molecules/Sections/SectionVendors"
import SectionWorkway from "@molecules/Sections/SectionWorkway"

export default function HomePage(props) {
  return (
    <Layout>
      {/* site seo */}
      <Seo title="الصفحة الرئيسية" lang="ar" />

      <SectionHeader />

      <SectionWorkway />

      <SectionVendors />

      {/* <SectionSellcar />

        <SectionReviews />
        */}
    </Layout>
  )
}
